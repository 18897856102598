

const Footer = () => {
    return (
        <div className="footer">
        <p>Pokémon is a The Pokémon Company trademark or whatever</p>
        <p>Dan Nisenson 2022</p>
        </div>
    )
}

export default Footer;